import {ActivatedRoute, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase/app'
import {Observable} from 'rxjs';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(public _firebaseAuth: AngularFireAuth, public router: Router, private activatedRoute: ActivatedRoute) {
    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        } else {
          this.userDetails = null;
        }
      }
    );

  }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    // your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    // uncomment above firebase auth code and remove this temp code
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(true);
      }, 1000);
    });

  }

  logout() {
    this._firebaseAuth.signOut();
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;
  }

  logoutExpire() {
    localStorage.clear();
    window.location.href = 'http://arkteckeycloak.orangestaging.com/auth/realms/arktec/protocol/openid-connect/auth?client_id=arktecpod&redirect_uri=' + encodeURIComponent(window.location.origin)
    
  }

  getToken() {
    return localStorage.token
  }
}
