<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <span class="text">Arktec RH</span>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">

          <!-- grid -->
          <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2 mr-3" id="drp-notification"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><i class="ft-grid font-medium-4"></i><span
                class="notification badge badge-pill "> </span></a>

            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="scrollable-container" [perfectScrollbar]>

                <a class="d-flex justify-content-between" href="{{redirectRh}}" target="_blank">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/RH.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        Arktec Recursos Humanos
                      </h6>
                    </div>
                  </div>
                </a>

                <a class="d-flex justify-content-between" href="{{redirectNf}}" target="_blank">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/NF.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        Arktec Notas Fiscais
                      </h6>
                    </div>
                  </div>
                </a>

                <a class="d-flex justify-content-between" href="{{redirectPOD}}" target="_blank">
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/PD.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="noti-text font-small-3 text-bold-500 m-0">
                        Arktec POD
                      </h6>
                    </div>
                  </div>
                </a>

              </li>
            </ul>
          </li>

          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown>
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{storageUtils.getNameUser()}}</span><span
                  class="text-right text-muted font-small-3">Disponível</span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/avatar.png" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!--              <a class="dropdown-item" href="javascript:;">-->
              <!--                <div class="d-flex align-items-center">-->
              <!--                  <i class="ft-message-square mr-2"></i><span>Chat</span>-->
              <!--                </div>-->
              <!--              </a>-->
              <!--              <a class="dropdown-item" href="javascript:;">-->
              <!--                <div class="d-flex align-items-center">-->
              <!--                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>-->
              <!--                </div>-->
              <!--              </a>-->
              <!--              <a class="dropdown-item" href="javascript:;">-->
              <!--                <div class="d-flex align-items-center">-->
              <!--                  <i class="ft-mail mr-2"></i><span>My Inbox</span>-->
              <!--                </div>-->
              <!--              </a>-->
              <!--              <div class="dropdown-divider"></div>-->
              <a class="dropdown-item" (click)="logOut()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Sair</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>