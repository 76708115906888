import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Constants } from 'app/utils/constants';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuth = this.authService.isAuthenticated();
    if (!isAuth) {
      
      window.location.href = 'http://arkteckeycloak.orangestaging.com/auth/realms/arktec/protocol/openid-connect/auth?client_id=arktecpod&redirect_uri='+ window.location.origin
    }
    else {
      return true;
    }
  }
}
